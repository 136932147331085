// 公共业务接口
import { upload, post } from '@/api'

// 上传文件
export const uploadBase64Img = data =>
  upload(
    '/dapi/b-access-layer/v1/access/knowledge-support-service/v1/support/picture/uploadCI',
    data
  )

// 获取 素材 ID
export const getMaterialId = data =>
  post('/dapi/qw-proxy-gateway/wxwork/v1/media/uploadByCosUrl', data)

// 根据 ORG ID 获取组织人员
export const getEmployeeByOrgIdWithPage = params =>
  post('/dapi/iov-common-data-service/v1/employee/page_employee_by_orgId', params)
